import React from 'react';
import './privacypolicy.css'
const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div id='privacyPolicy'>
        <h1>Privacy Policy</h1>
        <p>This privacy notice for AS Technology (P) Limited ('we', 'us', or 'our') describes how and why we might
          collect, store, use, and/or share ('process') your information when you use our services ('Services').</p>
        <h2>SUMMARY OF KEY POINTS</h2>
        <p>This summary provides key points from our privacy notice, but you can find out more details about any of
          these topics by clicking the link following each key point or by using our table of contents below to find
          the section you are looking for.</p>
        <p>What personal information do we process? When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services, the choices you make, and the products and
          features you use. Learn more about personal information you disclose to us.</p>
        <p>Do we process any sensitive personal information? We do not process sensitive personal information.</p>
        <p>Do we receive any information from third parties? We do not receive any information from third parties.</p>
        <p>How do we process your information? We process your information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply with law. We may also process your
          information for other purposes with your consent. We process your information only when we have a valid legal
          reason to do so. Learn more about how we process your information.</p>
        <p>In what situations and with which parties do we share personal information? We may share information in specific
          situations and with specific third parties. Learn more about when and with whom we share your personal
          information.</p>
        <p>How do we keep your information safe? We have organisational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission over the internet or information storage
          technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals,
          or other unauthorised third parties will not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about how we keep your information safe.</p>
        <p>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you
          have certain rights regarding your personal information. Learn more about your privacy rights.</p>
        <p>How do you exercise your rights? The easiest way to exercise your rights is by submitting a data subject access
          request, or by contacting us. We will consider and act upon any request in accordance with applicable data
          protection laws.</p>
        <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>
        <h2>TABLE OF CONTENTS</h2>
        <ol>
          <li><a href="#section1">WHAT INFORMATION DO WE COLLECT?</a></li>
          <li><a href="#section2">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
          <li><a href="#section3">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
          <li><a href="#section4">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
          <li><a href="#section5">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
          <li><a href="#section6">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
          <li><a href="#section7">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
          <li><a href="#section8">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
          <li><a href="#section9">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
          <li><a href="#section10">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
          <li><a href="#section11">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
        </ol>
        <h3 id="section1">1. WHAT INFORMATION DO WE COLLECT?</h3>
        <p><strong>Personal information you disclose to us</strong></p>
        <p><em>In Short:</em> We collect personal information that you provide to us.</p>
        <p>We collect personal information that you voluntarily provide to us when you express an interest in obtaining
          information about us or our products and Services, when you participate in activities on the Services, or
          otherwise when you contact us.</p>
        <p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the
          context of your interactions with us and the Services, the choices you make, and the products and features you
          use. The personal information we collect may include the following:</p>
        <ul>
          <li>Phone numbers</li>
        </ul>
        <p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>
        <p><strong>Application Data.</strong> If you use our application(s), we also may collect the following information
          if
          you choose to provide us with access or permission:</p>
        <ul>
          <li><strong>Geolocation Information.</strong> We may request access or permission to track location-based
            information from your mobile device, either continuously or while you are using our mobile application(s),
            to
            provide certain location-based services. If you wish to change our access or permissions, you may do so in
            your device's settings.</li>
          <li><strong>Mobile Device Access.</strong> We may request access or permission to certain features from your
            mobile device, including your mobile device's camera, storage, and other features. If you wish to change our
            access or permissions, you may do so in your device's settings.</li>
          <li><strong>Push Notifications.</strong> We may request to send you push notifications regarding your account or
            certain features of the application(s). If you wish to opt out from receiving these types of communications,
            you may turn them off in your device's settings.</li>
        </ul>
        <p>This information is primarily needed to maintain the security and operation of our application(s), for
          troubleshooting, and for our internal analytics and reporting purposes.</p>
        <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of
          any changes to such personal information.</p>
        <p><strong>Information automatically collected</strong></p>
        <p><em>In Short:</em> Some information — such as your Internet Protocol (IP) address and/or browser and device
          characteristics — is collected automatically when you visit our Services.</p>
        <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does
          not reveal your specific identity (like your name or contact information) but may include device and usage
          information, such as your IP address, browser and device characteristics, operating system, language
          preferences,
          referring URLs, device name, country, location, information about how and when you use our Services, and other
          technical information. This information is primarily needed to maintain the security and operation of our
          Services, and for our internal analytics and reporting purposes.</p>
        <p>The information we collect includes:</p>
        <ul>
          <li><strong>Location Data.</strong> We collect location data such as information about your device's location,
            which can be either precise or imprecise. How much information we collect depends on the type and settings
            of
            the device you use to access the Services. For example, we may use GPS and other technologies to collect
            geolocation data that tells us your current location (based on your IP address). You can opt out of allowing
            us to collect this information either by refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the
            Services.</li>
        </ul>
        <h3 id="section2">2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
        <p><em>In Short:</em> We process your information to provide, improve, and administer our Services, communicate with
          you, for security and fraud prevention, and to comply with law. We may also process your information for other
          purposes with your consent.</p>
        <p>We process your personal information for a variety of reasons, depending on how you interact with our Services,
          including:</p>
        <ul>
          <li>To send administrative information to you. We may process your information to send you details about our
            products and services, changes to our terms and policies, and other similar information.</li>
        </ul>
        <h3 id="section3">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
        <p><em>In Short:</em> We may share information in specific situations described in this section and/or with the
          following third parties.</p>
        <p>We may need to share your personal information in the following situations:</p>
        <ul>
          <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during
            negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
            business to another company.</li>
        </ul>
        <h3 id="section4">4. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
        <p><em>In Short:</em> We keep your information for as long as necessary to fulfill the purposes outlined in this
          privacy notice unless otherwise required by law.</p>
        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
          other legal requirements).</p>
        <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or
          anonymise such information, or, if this is not possible (for example, because your personal information has been
          stored in backup archives), then we will securely store your personal information and isolate it from any
          further processing until deletion is possible.</p>
        <h3 id="section5">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
        <p>In Short: We aim to protect your personal information through a system of organisational and technical security
          measures.</p>
        <p>urity of any personal information we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or information storage technology can be guaranteed to
          be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third
          parties will not be able to defeat our security and improperly collect, access, steal, or modify your
          information. Although we will do our best to protect your personal information, transmission of personal
          information to and from our Services is at your own risk. You should only access the Services within a secure
          environment.</p>
        <h3 id="section6">6. DO WE COLLECT INFORMATION FROM MINORS?</h3>
        <p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>
        <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
          represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
          minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of
          age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data
          from our records. If you become aware of any data we may have collected from children under age 18, please
          contact us at astindia9@gmail.com.</p>
        <h3 id="section7">7. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
        <p>In Short: You may review, change, or terminate your account at any time.</p>
        <p>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be
          express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at
          any time. You can withdraw your consent at any time by contacting us by using the contact details provided in
          the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</p>
        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when
          applicable law allows, will it affect the processing of your personal information conducted in reliance on
          lawful processing grounds other than consent.</p>
        <p>If you have questions or comments about your privacy rights, you may email us at astindia9@gmail.com.</p>
        <h3 id="section8">8. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
        <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT')
          feature or setting you can activate to signal your privacy preference not to have data about your online
          browsing activities monitored and collected. At this stage no uniform technology standard for recognising and
          implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any
          other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
          tracking is adopted that we must follow in the future, we will inform you about that practice in a revised
          version of this privacy notice.</p>
        <h3 id="section9">9. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
        <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
        <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated
          'Revised' date and the updated version will be effective as soon as it is accessible. If we make material
          changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of
          how we are protecting your information.</p>
        <h3 id="section10">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
        <p>If you have questions or comments about this notice, you may email us at <a href="mailto:astindia9@gmail.com">astindia9@gmail.com</a> or contact us by post at:</p>
        <address>
          AS Technology (P) Limited<br />
          G-21, 22 &208, Archana Arcade, Behind Hotel Ramakrishna, <br />
          Secunderabad - 500 025, Telangana 500081, India
        </address>
        <h3 id="section11">11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
        <p>Based on the applicable laws of your country, you may have the right to request access to the personal
          information we collect from you, change that information, or delete it. To request to review, update, or delete
          your personal information, please fill out and submit a data subject access request.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
