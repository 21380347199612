import logo from '../../logo.png';
import { NavLink } from 'react-router-dom';
import './navbar.css'
import { useRef } from 'react';
export default function Navbar() {
    const nav = useRef(null);
    const navItems = useRef(null);
    const menuBtn = useRef(null);
    const closeBtn = useRef(null);
    document.onscroll = () => {
        if (window.scrollY > 0) {
            nav.current.classList.add('scroll-attempt')
        }
        else {
            nav.current.classList.remove('scroll-attempt')
        }
        window.scrollY>500?document.getElementById('backtop').style.display = "flex":document.getElementById('backtop').style.display = "none";

    }
    function menuClicked() {
        if (menuBtn.current.innerHTML === '<i class="fa-solid fa-bars"></i>') {
            console.log("Menu");
            closeBtn.current.style.display = "flex";
            menuBtn.current.style.display = "none";
            navItems.current.style.display = "flex";
        }
        if (menuBtn.current.innerHTML === '<i class="fa-solid fa-xmark"></i>') {
            console.log("Close");
            menuBtn.current.innerHTML = '<i class="fa-solid fa-bars"></i>';
            navItems.current.style.display = "none";
        }
    }
    function closeClicked() {

        console.log("Menu");
        menuBtn.current.style.display = "flex";
        closeBtn.current.style.display = "none";
        navItems.current.style.display = "none";

    }
    function navClicked(e) {
        console.log(navItems.current.style.display = "none");
        menuBtn.current.innerHTML = '<i class="fa-solid fa-bars"></i>';
        menuBtn.current.style.display = "flex";
        closeBtn.current.style.display = "none";
        navItems.current.style.display = "none";
    }

    return (
        <nav ref={nav}>
            <div className="container">
                <div className="navbar">
                    <div className="logo">
                        <NavLink to='/'><img src={logo} className='img-fluid' alt="" /></NavLink>
                    </div>
                    <div className="nav-items">
                        <ul ref={navItems} onClick={navClicked}>
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about">About Us</NavLink>
                            </li>
                            <li>
                                <NavLink to="/services">Services</NavLink>
                            </li>
                            <li>
                                <NavLink to="/partners">Partners</NavLink>
                            </li>
                            <li>
                                <NavLink to="/clients">Clients</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact">Contact Us</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div onClick={menuClicked} ref={menuBtn} className="menu-btn"><i class="fa-solid fa-bars"></i></div>
                    <div onClick={closeClicked} ref={closeBtn} style={{ "display": "none" }} className="menu-btn close"><i class="fa-solid fa-xmark"></i></div>
                </div>
            </div>
        </nav>

    )
}
