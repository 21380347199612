const validateEmail = ({ email, setEmailError, emailTouched }) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailTouched) {
        setEmailError(false); // Don't show error if field hasn't been touched
    } else if (!email) {
        setEmailError("Email Address Required");
    } else if (!emailRegex.test(email)) {
        setEmailError("Invalid Email Address");
    } else {
        setEmailError(false);
    }
};

const validatePhone = ({ phone, setPhoneError, phoneTouched }) => {
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!phoneTouched) {
        setPhoneError(false); // Don't show error if field hasn't been touched
    } else if (!phone) {
        setPhoneError("Phone Number Required");
    } else if (!phoneRegex.test(phone)) {
        setPhoneError("Invalid Phone Number");
    } else {
        setPhoneError(false);
    }
};

const validateFullName = ({ fullName, setFullNameError, fullNameTouched }) => {
    if (!fullNameTouched) {
        setFullNameError(false); // Don't show error if field hasn't been touched
    } else if (!fullName) {
        setFullNameError("Full Name Required");
    } else if (fullName.length < 5) {
        setFullNameError("Full Name Too Short");
    } else if (fullName.length > 30) {
        setFullNameError("Full Name Too Long");
    } else {
        setFullNameError(false);
    }
};

const validateSubject = ({ subject, setSubjectError, subjectTouched }) => {
    if (!subjectTouched) {
        setSubjectError(false); // Don't show error if field hasn't been touched
    } else if (!subject) {
        setSubjectError("Subject Required");
    } else {
        setSubjectError(false);
    }
};

const validateMessage = ({ message, setMessageError, messageTouched }) => {
    if (!messageTouched) {
        setMessageError(false); // Don't show error if field hasn't been touched
    } else if (!message) {
        setMessageError("Message Required");
    } else {
        setMessageError(false);
    }
};

export { validateEmail, validatePhone, validateFullName, validateSubject, validateMessage };

