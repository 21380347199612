import './about.css';
import AboutImg from './about.svg'
import MissionImg from './mission.svg'
import Counter from '../Counter/Counter';
import Landing from '../SmallLanding/Landing';
export default function About() {
  return (
    <>
      <Landing heading={"About Us"} />
      <section className='about'>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 about-content">
              <div className="sub-heading">Leading IT Solutions and Services with Proven Expertise and Excellence</div>
              <p className='mt-4'>AS Technology (P) Ltd., popularly known as AST a ISO 9001:2015 company incorporation in 1999 and a leading Provider of IT & IT enabled services, Develop Web Applications and Manage IT Services organization. We Provide our practical and relevant technology platforms, process excellence, deep domain expertise and strategic enterprise partnerships to deliver superior business results for our clients.</p>
            </div>
            <div className="col-lg-6 about-img">
              <img src={AboutImg} alt="" />
            </div>
          </div>
        </div>
        {/* <Counter /> */}
        {/* <div className="text-center mt-5">
          <div className="line"></div>
          <div className="heading">OUR MISSION</div>
        </div>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <img src={MissionImg} alt="" />
            </div>
            <div className="col-lg-6">
              <div className="sub-heading">Helping businesses deliver exceptional buyer experiences.</div>
              <p>In the 14 years since its inception, CTIS has pioneered the development of innovative Information Technology (IT), IT Enabled Service (ITES), Information & Communication Technology (ICT), System Integration and different flavors of Inbound & Outbound Services.</p>
              <p>With over 300 engineering staff pan India, CTIS has set its sights deep into the future of Good Governance, way ahead of our time. CTIS, with high expertise in cutting edge technologies has emerged as Link trailblazer in ICT & IOT Technologies creating opportunities in e-Governance, System Integration, Analytics and IT Infrastructure Management Services.</p>
            </div>
          </div>
        </div> */}
      </section>
    </>
  )
}
