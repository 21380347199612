import React, { useEffect, useState } from 'react';
import './contact.css';
import contactMain from './Contact.jpg';
import Landing from '../SmallLanding/Landing';
import { validateEmail, validatePhone, validateFullName, validateMessage, validateSubject } from './validation';
import Loader from "../Loader/Loader";
import Swal from 'sweetalert2';
import 'animate.css';

export default function Contact() {
    const [loader, setLoader] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [fullNameError, setFullNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [fullNameTouched, setFullNameTouched] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [phoneTouched, setPhoneTouched] = useState(false);
    const [subjectTouched, setSubjectTouched] = useState(false);
    const [messageTouched, setMessageTouched] = useState(false);

    useEffect(() => {
        validateEmail({ email, setEmailError, emailTouched });
        validatePhone({ phone, setPhoneError, phoneTouched });
        validateFullName({ fullName, setFullNameError, fullNameTouched });
        validateSubject({ subject, setSubjectError, subjectTouched });
        validateMessage({ message, setMessageError, messageTouched });
    }, [email, phone, fullName, subject, message, emailTouched, phoneTouched, fullNameTouched, subjectTouched, messageTouched]);

    const handleBlur = (setStateTouched) => () => {
        setStateTouched(true);
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        if (!phoneError && !emailError && !phoneError && !fullNameError && !subjectError && !messageError) {
            setLoader(true);
            try {
                const res = await fetch('/send', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ fullName, email, phone, subject, message })
                });
                const data = await res.json();

                if (data.status === 201) {
                    Swal.fire({
                        title: 'Submitted',
                        text: 'We will contact you soon',
                        icon: 'success',
                        confirmButtonText: 'Go back'
                    });
                    setEmail("");
                    setFullName("");
                    setMessage("");
                    setPhone("");
                    setSubject("");
                } else {
                    let errorMessage = 'Internal server error';
                    if (data.error && data.error.code === "EENVELOPE") {
                        errorMessage = 'Invalid email address';
                    }
                    Swal.fire({
                        title: 'Error',
                        text: errorMessage,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                console.error('Error sending email:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to send email. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } finally {
                setLoader(false);
            }
        }
    };

    return (
        <>
            {loader && <Loader />}
            <Landing heading="Contact us" />
            <div className='contact'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 animate__animated animate__bounceInLeft">
                            <div className="sub-heading">Let's get in touch</div>
                            <form onSubmit={sendEmail}>
                                <div className="form-item">
                                    <label htmlFor="fullName">Full Name<span className='red'>*</span></label>
                                    <input id="fullName" placeholder='Full Name' value={fullName} type="text" autoFocus required onChange={(e) => setFullName(e.target.value)} onBlur={handleBlur(setFullNameTouched)} />
                                    {fullNameTouched && fullNameError && <span className="validationError animation">{fullNameError}</span>}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="email">Email<span className='red'>*</span></label>
                                    <input id="email" placeholder='Email' value={email} type="email" autoComplete="off" required onChange={(e) => setEmail(e.target.value)} onBlur={handleBlur(setEmailTouched)} />
                                    {emailTouched && emailError && <span className="validationError animation">{emailError}</span>}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="phone">Mobile<span className='red'>*</span></label>
                                    <input id="phone" placeholder='Mobile' maxLength="10" value={phone} type="text" pattern='^[0-9]*$' required onChange={(e) => setPhone(e.target.value)} onBlur={handleBlur(setPhoneTouched)} />
                                    {phoneTouched && phoneError && <span className="validationError animation">{phoneError}</span>}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="subject">Subject<span className='red'>*</span></label>
                                    <input id="subject" placeholder='Subject' value={subject} type="text" required onChange={(e) => setSubject(e.target.value)} onBlur={handleBlur(setSubjectTouched)} />
                                    {subjectTouched && subjectError && <span className="validationError animation">{subjectError}</span>}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="message">Message<span className='red'>*</span></label>
                                    <textarea id="message" placeholder='Message' value={message} required onChange={(e) => setMessage(e.target.value)} onBlur={handleBlur(setMessageTouched)} />
                                    {messageTouched && messageError && <span className="validationError animation">{messageError}</span>}
                                </div>
                                <button className='btn secondary' type='submit'>Submit</button>
                            </form>
                        </div>
                        <div className="contact-image col-xl-6 col-lg-6 col-md-6 text-center">
                            <img src={contactMain} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
