import React from 'react';
import './loader.css';

export default function Loader() {
  return (
    <div className='loader'>
      <svg fill='#22405c' width={99} height={99} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <style dangerouslySetInnerHTML={{__html: ".spinner_hzlK{animation:spinner_vc4H .8s linear infinite;animation-delay:-.8s}.spinner_koGT{animation-delay:-.65s}.spinner_YF1u{animation-delay:-.5s}@keyframes spinner_vc4H{0%{y:1px;height:22px}93.75%{y:5px;height:14px;opacity:.2}}" }} />
        <rect className="spinner_hzlK" x={1} y={1} width={6} height={22} />
        <rect className="spinner_hzlK spinner_koGT" fill='#f99702' x={9} y={1} width={6} height={22} />
        <rect className="spinner_hzlK spinner_YF1u" x={17} y={1} width={6} height={22} />
      </svg>
      <h1>Submitting...</h1>
    </div>
  );
}
