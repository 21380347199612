import { NavLink } from 'react-router-dom';
import './footer.css';
import Logo from '../../logo.png';
export default function Footer() {
    return (
        <div className="footer">
            <div className='container'>
                <div className="row">
                    <div className="col col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
                        <div className="item d-flex align-items-center">
                            <img style={{filter: 'invert(1)'}} src={Logo} className='img-fluid' alt="" />
                        </div>
                    </div>
                    <div className="col col-xl-4 col-lg-4 col-md-8 col-sm-8 col-12">
                        <h5>Corporate Office Hyderabad</h5>
                        <p>G-21, 22 &208, Archana Arcade, Behind Hotel Ramakrishna, Secunderabad - 500 025.</p>
                        <div className="icons">
                            <NavLink to="https://in.linkedin.com/company/c-tel-infosystems"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                            <NavLink to=""><i className="fa-brands fa-twitter"></i></NavLink>
                            <NavLink to=""><i className="fa-brands fa-facebook-f"></i></NavLink>
                        </div>
                    </div>
                    <div className="col">
                        <h5>Our Offices</h5>
                        <p>Hyderabad</p>
                        <p>Vijaywada</p>
                    </div>
                    <div className="col">
                        <h5>Quick Links</h5>
                        <p><NavLink to="">Home</NavLink></p>
                        <p><NavLink to="/services">Services</NavLink></p>
                    </div>
                    <div className="col">
                        <h5>Others</h5>
                        <p><NavLink to="/about">About Us</NavLink></p>
                        <p><NavLink to="/privacy-policy">Privacy Policy</NavLink></p>
                        <p><NavLink to="/contact/#main">Contact Us</NavLink></p>
                    </div>
                </div>
            </div>
        </div>
    )
}