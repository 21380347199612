import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import Services from "./components/Services/Services"
import Partners from "./components/Partners/Partners"
import Clients from "./components/Clients/Clients"
import Contact from "./components/Contact/Contact"
import Projects from "./components/Projects/Projects"
import ErrorPage from "./components/Error"
import AOS from 'aos';
import 'aos/dist/aos.css';
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import BackTop from "./components/BackTop/BackTop";
const App = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className='App'>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
        <BackTop />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
