import './projects.css'
import Landing from '../SmallLanding/Landing';

export default function Projects() {
  return (
    <>
      <Landing heading={"Our Works"} />
      <section className='projects'>
        <h1>Comming Soon...</h1>
      </section>
    </>
  )
}
