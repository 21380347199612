import Landing from "../SmallLanding/Landing"
import '../Home/home.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import AP from './Andhra_Pradesh.png'
import Telangana from './Telangana.svg'
import TelanganaExcise from './telanganaExcise.png'
import apts from './apts.jpeg'
import tgts from './tgts.png'
import aptonline from './aptonline.jpeg'
import meeSevaLogo from './meeSevaLogo.png'

export default function Clients() {
  return (
    <div>
      <Landing heading={"Our Clients"} />

      <div className="reviews">
        <div className="container">
          {/* <div className="sub-heading"><i class="fa-regular fa-handshake"></i> Our Clients</div> */}
          <Swiper
            spaceBetween={50}
            loop={true}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              330: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 6,
              },
              768: {
                slidesPerView: 8,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Autoplay, Pagination]}
          >

            <SwiperSlide>
              <div className="item">
                <img src={AP} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Telangana} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={apts} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={tgts} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={aptonline} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={TelanganaExcise} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={meeSevaLogo} alt="" width={75} />
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
    </div>
  )
}
