import React, { useRef } from 'react';
import './backtop.css'
const BackTop = () => {
    const top = useRef(null);
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
        console.log(window.scrollY);
    }
    return (
        <div ref={top} id='backtop' onClick={scrollTop}>
            <i className="fa-solid fa-arrow-up"></i>
        </div>
    );
}

export default BackTop;
