import './services.css'
import UIUX from './erp-supply_chain_management_mobile.png'
import development from './Track.webp'
import webdevelopment from './web-app-development.svg'
import mobdevelopment from './mobdev.jpg'
import Landing from '../SmallLanding/Landing'
export default function Services() {
  return (
    <section className='services'>
      <Landing heading={"SERVICES"} />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="line"></div>
            <div className="heading">Supply Chain Management</div>
            <div className="sub-heading-small">Streamlining Liquor Supply Chain Automation: From Production to Retail with Enhanced Traceability and Counterfeit Reduction</div>
            <p className='para'>AST Supply Chain Management Solution for liquor supply chain automation for the Liquor Manufacturers under State Excise Departments enabling process automation for Distilleries, Bottling Plants, Depots, Wholesale warehouses and Retailers. This enables individual products to be tracked from production to the end customer at every stakeholder and helps to significantly reduce counterfeiting by ensuring products can be easily tracked.</p>
          </div>
          <div className="col-lg-6">
            <img className='img-fluid' src={UIUX} alt="" />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <img style={{ height: '100%', objectFit: 'cover' }} className='img-fluid' src={development} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="line"></div>
            <div className="heading">Track & Trace System</div>
            <div className="sub-heading-small">Track and Trace: Scalable Serialization and Inspection for a Secure Liquor Supply Chain</div>
            <p className='para'>AST Track and Trace is an intelligent end-to-end solution that integrates tracking, serialisation, vision inspection and server management. Our Track and Trace systems are built to ensure compliance with the highest global regulatory standards for a transparent and secure supply chain. Our robust serialization software and vision inspection solutions combine with powerful global server management for a scalable track and trace solution that is easy to install and maintain. AST customers are leading brands in liquor & beverages manufacturers.</p>
          </div>

        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="line"></div>
            <div className="heading">Web Application Development</div>
            <div className="sub-heading-small">Application Development & Maintenance: Streamlining Business Processes with Custom, Cost-Efficient Solutions</div>
          <p className='para'>AST delivers custom application development and maintenance services to automate and optimize business processes. By leveraging advanced technology and thorough business analysis, we create state-of-the-art solutions that reduce time, costs, and bottlenecks. Our end-to-end offshore development services cater to web and mobile projects, helping businesses achieve efficiency and cost-effectiveness.</p>
          </div>
          <div className="col-lg-6">
            <img style={{ height: '100%', objectFit: 'cover' }} className='img-fluid' src={webdevelopment} alt="" />
          </div>
        </div>
        <div className="row mt-5">
        <div className="col-lg-6">
            <img style={{ height: '100%', objectFit: 'cover' }} className='img-fluid' src={mobdevelopment} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="line"></div>
            <div className="heading">Mobile Application Development</div>
            <div className="sub-heading-small">Tailored Mobile App Development with Cutting-Edge Design and On-Time Delivery</div>
          <p className='para'>AST provides you the chance to order your own customized apps which can meet the needs of your customer base bigger than anyone else on the market. AST gives new innovations in terms of design and development of mobile apps by justifying the client’s requirements. AST had the dedicated teams for Mobile App Development have different teams for iPhone, Android applications and on-time delivery to meet a wide range of requirements.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
