import Landing from "../SmallLanding/Landing"
import '../Home/home.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import samsung from '../Partners/samsung.svg'
import intel from '../Partners/Intel.png'
import Hp from '../Partners/HP.png'
import Dell from '../Partners/Dell.png'
import Lenovo from '../Partners/Lenovo.png'
import Logitech from '../Partners/Logitech.png'
import TVS from '../Partners/TVS.png'
import Epson from '../Partners/Epson.png'
import APC from '../Partners/APC.png'
export default function Partners() {
  return (
    <div>
        <Landing heading={"Our Partners"} />

        <div className="reviews">
        <div className="container">
          {/* <div className="sub-heading"><i class="fa-regular fa-handshake"></i> Our Partners</div> */}
          <Swiper
            spaceBetween={50}
            loop={true}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              330: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 6,
              },
              768: {
                slidesPerView: 8,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Autoplay, Pagination]}
          >

            <SwiperSlide>
              <div className="item">
                <img src={samsung} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={intel} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Hp} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Dell} alt="" />

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Lenovo} alt="" />

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Logitech} alt="" />

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={TVS} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Epson} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={APC} alt="" />
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
    </div>
  )
}
