import './home.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import Counter from '../Counter/Counter.js';
import LandingImg from './landing3.png';
import webdev from './icons8-web-development-60.png';
import trackntrace from './icons8-track-order-60.png';
import supplychain from './icons8-supply-chain-60.png';
import mobdev from './icons8-mobile-app-development-60.png';
import team from './Team.png';
import wave from './wave.svg';
import project1 from './project1.jpg';
import project2 from './project2.png';
import project3 from './project3.png';
import profile1 from './profile.png';
import samsung from '../Partners/samsung.svg'
import intel from '../Partners/Intel.png'
import Hp from '../Partners/HP.png'
import Dell from '../Partners/Dell.png'
import Lenovo from '../Partners/Lenovo.png'
import Logitech from '../Partners/Logitech.png'
import TVS from '../Partners/TVS.png'
import Epson from '../Partners/Epson.png'
import APC from '../Partners/APC.png'
import AP from '../Clients/Andhra_Pradesh.png'
import Telangana from '../Clients/Telangana.svg'
import { Link } from 'react-router-dom';
import TelanganaExcise from '../Clients/telanganaExcise.png'
import apts from '../Clients/apts.jpeg'
import tgts from '../Clients/tgts.png'
import aptonline from '../Clients/aptonline.jpeg'
import meeSevaLogo from '../Clients/meeSevaLogo.png'
export default function Home() {
  return (
    <section id='home'>
      <div className="landing-section">
        <div className="landing-bg">
          <div className="container ">
            <div className="landing row">
              <div className="content col-lg-6">
                <h1 className='main-heading'>Revolutionizing Supply Chains and Digital Solutions for a Smarter Future.</h1>
                <p className='para'>We delivers cutting-edge IT solutions to optimize your business. From supply chain automation to custom web and mobile apps, our expertise drives efficiency and innovation.</p>
                <Link className='btn primary' to="/services">Explore Services</Link>
              </div>
              <div className="image col-lg-6">
                <img className='img-fluid' src={LandingImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wave">
        <img src={wave} alt="" />
      </div>

      <div className="services" data-aos="fade-up">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="services-box justify-content-center row col-lg-6" data-aos="fade-up">
              <div className="item col-6">
                <div className="icon">
                  <img src={webdev} alt="" />
                </div>
                <div className="technology">
                  Web Application Development
                </div>
                <div className="desc">
                  Custom web applications that streamline processes and boost efficiency.
                </div>
              </div>
              <div className="item item-top-margin col-6">
                <div className="icon">
                  <img src={supplychain} alt="" />
                </div>
                <div className="technology">
                  Supply Chain Management
                </div>
                <div className="desc">
                  Automated supply chain solutions to track and manage every stage of your product journey.
                </div>
              </div>
              <div className="item col-6">
                <div className="row">

                  <div className="icon">
                    <img src={trackntrace} alt="" />

                  </div>

                  <div className="technology">
                    Track & Trace System
                  </div>

                </div>
                <div className="desc">
                  End-to-end track and trace solutions for a secure and compliant supply chain
                </div>
              </div>
              <div className="item item-top-margin col-6">
                <div className="icon">
                  <img src={mobdev} alt="" />
                </div>
                <div className="technology">
                  Mobile Application Development
                </div>
                <div className="desc">
                  Tailored mobile apps designed to meet your unique business needs and engage your audience
                </div>
              </div>
            </div>
            <div className="services-content col-lg-6">
              <div className="line"></div>
              <h5 className='heading'>Services</h5>
              <h1 className='sub-heading'>Comprehensive IT Solutions for Streamlined Operations and Enhanced Efficiency</h1>
              <p className='para'>We offers end-to-end IT solutions including supply chain management, track and trace systems, custom web and mobile app development. Our services are designed to automate processes, ensure compliance, and drive efficiency across all aspects of your business.</p>
              <Link className='btn primary' to={'/services'}>Explore Services</Link>
            </div>
          </div>
        </div>
      </div>
      {/* <Counter /> */}
      <div className="who" data-aos="fade-up">
        <div className="container">
          <div className="line"></div>
          <h3 className='heading'>Who We Are</h3>

          <div className="row">
            <div className="col-lg-6">
              <h1 className='sub-heading'> Leading IT Solutions and Services with Proven Expertise and Excellence</h1>
              <p className='para'>AS Technology (P) Ltd., popularly known as AST a ISO 9001:2015 company incorporation in 1999 and a leading Provider of IT & IT enabled services, Develop Web Applications and Manage IT Services organization. We Provide our practical and relevant technology platforms, process excellence, deep domain expertise and strategic enterprise partnerships to deliver superior business results for our clients.</p>
              <Link className='btn secondary' to={'/about'}>Learn More</Link>
            </div>
            <div className="col-lg-6">
              <img className='img-fluid' src={team} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="project-overview" data-aos="fade-up">
        <div className="project-container">
          <div className="container">

            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6">
                <div className="line"></div>
                <h3 className='heading'>Our Work</h3>
                <h1 className='sub-heading'> Our latest projects </h1>
              </div>
              <div className="col-lg-6">
                <p className='para'>We build products close to our heart, We make your idea to reality and make your dream successful with awesome experience.</p>
              </div>
            </div>
            <Swiper
              spaceBetween={50}
              slidesPerView={'auto'}
              centeredSlides={true}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                330: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
              }}
              navigation={true} modules={[Navigation]}
            >
              <SwiperSlide><div className="item col">
                <div className="image">
                  <img src={project1} alt="" />
                </div>
                <div className="content">
                  <div className="name">
                    <h4>Sass Website</h4>
                    <p>Devlopment <br /> Jan 19, 2024</p>
                  </div>
                  <div className="">
                    <Link to={"/projects"} className='btn secondary'>See Project</Link>
                  </div>
                </div>
              </div></SwiperSlide>
              <SwiperSlide><div className="item col">
                <div className="image">
                  <img src={project2} alt="" />
                </div>
                <div className="content">
                  <div className="name">
                    <h4>Sass Website</h4>
                    <p>Devlopment <br /> Jan 19, 2024</p>
                  </div>
                  <div className="">
                    <Link to={"/projects"} className='btn secondary'>See Project</Link>
                  </div>
                </div>
              </div></SwiperSlide>
              <SwiperSlide><div className="item col">
                <div className="image">
                  <img src={project3} alt="" />
                </div>
                <div className="content">
                  <div className="name">
                    <h4>Sass Website</h4>
                    <p>Devlopment <br /> Jan 19, 2024</p>
                  </div>
                  <div className="">
                    <Link to={"/projects"} className='btn secondary'>See Project</Link>
                  </div>
                </div>
              </div></SwiperSlide>
              <SwiperSlide>
                <div className="item col">
                  <div className="image">
                    <img src={project1} alt="" />
                  </div>
                  <div className="content">
                    <div className="name">
                      <h4>Sass Website</h4>
                      <p>Devlopment <br /> Jan 19, 2024</p>
                    </div>
                    <div className="">
                      <Link to={"/projects"} className='btn secondary'>See Project</Link>
                    </div>
                  </div>
                </div></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div> */}
      <div className="reviews">
        <div className="container">
          <div style={{ textAlign: 'center' }} className="sub-heading">Our Partners</div>
          <Swiper
            spaceBetween={50}
            loop={true}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              330: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 6,
              },
              768: {
                slidesPerView: 8,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Autoplay, Pagination]}
          >

            <SwiperSlide>
              <div className="item">
                <img src={samsung} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={intel} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Hp} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Dell} alt="" />

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Lenovo} alt="" />

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Logitech} alt="" />

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={TVS} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Epson} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={APC} alt="" />
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
      <div className="reviews">
        <div className="container">
          <div style={{ textAlign: 'center' }} className="sub-heading">Our Clients</div>
          <Swiper
            spaceBetween={50}
            loop={true}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              330: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 6,
              },
              768: {
                slidesPerView: 8,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Autoplay, Pagination]}
          >

            <SwiperSlide>
              <div className="item">
                <img src={AP} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={Telangana} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={apts} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={tgts} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={aptonline} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={TelanganaExcise} alt="" width={75} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img src={meeSevaLogo} alt="" width={75} />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  )
}
